import * as C from "./styles";
import Navbar from "../../components/Navbar";
import { useEffect, useState } from "react";
import {
  formatCurrentMonth,
  formatMonthPrevious,
  getCurrentMonth,
} from "../../helpers/dateFilter";
import InputForm from "../../components/InputForm";
import { db } from "../../firebase/config";
import { collection, getDocs } from "firebase/firestore";
import generatePdfFinanceiro from "../../helpers/generatePdfFinanceiro";

export default function Financeiro() {
  const [filteredList, setFilteredList] = useState({});
  const [currentMonth] = useState(getCurrentMonth());

  const getUsers = async () => {
    try {
      const data = await getDocs(collection(db, "controlefinanceiro"));
      const currentMonth2 = new Date().getMonth() + 1;
      const groupedData = {};

      data.forEach((doc) => {
        const item = doc.data();
        const itemDate = new Date(item.date.seconds * 1000);
        const itemMonth = itemDate.getMonth() + 1;

        if (itemMonth === currentMonth2) {
          const category = item.category;
          if (groupedData[category]) {
            groupedData[category].push({
              id: doc.id,
              category: category,
              value: item.value,
              date: itemDate,
              title: item.title,
            });
          } else {
            groupedData[category] = [
              {
                id: doc.id,
                category: category,
                value: item.value,
                date: itemDate,
                title: item.title,
              },
            ];
          }
        }
      });

      const summedData = {};
      for (const category in groupedData) {
        const items = groupedData[category];
        const totalValue = items.reduce((acc, item) => acc + item.value, 0);
        summedData[category] = totalValue;
      }

      setFilteredList(summedData);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const calculateSpecialSum = (data) => {
    let specialSum = 0;

    if (data["Dizimo"]) {
      specialSum += data["Dizimo"];
    }

    if (data["Ofertas"]) {
      specialSum += data["Ofertas"];
    }

    return specialSum;
  };

  const calculateTotalSum = (data) => {
    let totalSum = 0;

    for (const category in data) {
      if (category !== "Dizimo" && category !== "Ofertas") {
        totalSum += data[category];
      }
    }

    return totalSum;
  };

  const totalSum = calculateTotalSum(filteredList);
  const specialSum = calculateSpecialSum(filteredList);

  const [formValues, setFormValues] = useState({
    Water: 0,
    Light: 0,
    InternetAndSofts: 0,
    Rent: 0,
    Transport: 0,
    MKT: 0,
    Food: 0,
    Fuel: 0,
    Acquisitions: 0,
    Social: 0,
    Missions: 0,
    Convention: 0,
    Administrative: 0,
    totalExit: 0,
    EntryMonthPrevious: 0,
    Balance: 0,
    EntryCurrentMonth: 0,
  });

  useEffect(() => {
    if (Object.keys(filteredList).length > 0 && totalSum !== 0) {
      setFormValues({
        ...formValues,
        Water: filteredList.Agua || 0,
        Light: filteredList.Luz || 0,
        InternetAndSofts: filteredList.Internet || 0,
        Rent: filteredList.Aluguel || 0,
        Transport: filteredList.Transportes || 0,
        MKT: filteredList.AssistenciaMKT || 0,
        Food: filteredList.Alimentacao || 0,
        Fuel: filteredList.Combustivel || 0,
        Acquisitions: filteredList.Aquisicoes || 0,
        Social: filteredList.AssistenciaSocial || 0,
        Missions: filteredList.Missoes || 0,
        Convention: filteredList.Convencao || 0,
        Administrative: filteredList.Administrativo || 0,
        totalExit: totalSum,
        Balance: formValues.EntryMonthPrevious - totalSum,
        EntryCurrentMonth: specialSum,
      });
    }
  }, [totalSum, filteredList, formValues.EntryMonthPrevious]);

  function onSubmit() {
    generatePdfFinanceiro(formValues, currentMonth);
  }

  return (
    <>
      <Navbar />
      <C.Container>
        <C.Form
          onSubmit={(ev) => {
            ev.preventDefault();
            onSubmit();
          }}
        >
          <C.FormTitle>Relatório Gerencial Lírio Parelheiros</C.FormTitle>

          <InputForm
            label="Agua"
            type="number"
            name="Water"
            value={formValues.Water}
            onChange={setFormValues}
          />
          <InputForm
            label="Luz"
            type="number"
            name="Light"
            value={formValues.Light}
            onChange={setFormValues}
          />
          <InputForm
            label="Internet e Softs"
            type="number"
            name="InternetAndSofts"
            value={formValues.InternetAndSofts}
            onChange={setFormValues}
          />
          <InputForm
            label="Aluguel"
            type="number"
            name="Rent"
            value={formValues.Rent}
            onChange={setFormValues}
          />
          <InputForm
            label="Transportes"
            type="number"
            name="Transport"
            value={formValues.Transport}
            onChange={setFormValues}
          />
          <InputForm
            label="MKT"
            type="number"
            name="MKT"
            value={formValues.MKT}
            onChange={setFormValues}
          />
          <InputForm
            label="Alimentação"
            type="number"
            name="Food"
            value={formValues.Food}
            onChange={setFormValues}
          />
          <InputForm
            label="Combustível"
            type="number"
            name="Fuel"
            value={formValues.Fuel}
            onChange={setFormValues}
          />
          <InputForm
            label="Aquisições"
            type="number"
            name="Acquisitions"
            value={formValues.Acquisitions}
            onChange={setFormValues}
          />

          <InputForm
            label="Social"
            type="number"
            name="Social"
            value={formValues.Social}
            onChange={setFormValues}
          />
          <InputForm
            label="Missões"
            type="number"
            name="Missions"
            value={formValues.Missions}
            onChange={setFormValues}
          />
          <InputForm
            label="Convenção"
            type="number"
            name="Convention"
            value={formValues.Convention}
            onChange={setFormValues}
          />
          <InputForm
            label="Administrativo"
            type="number"
            name="Administrative"
            value={formValues.Administrative}
            onChange={setFormValues}
          />

          <C.Section></C.Section>

          <InputForm
            label="Total de Saída"
            type="number"
            name="totalExit"
            value={formValues.totalExit}
            onChange={setFormValues}
          />
          <InputForm
            label={`Entrada ${formatMonthPrevious(currentMonth)}`}
            type="number"
            name="EntryMonthPrevious"
            value={formValues.EntryMonthPrevious}
            onChange={setFormValues}
          />
          <InputForm
            label="Saldo"
            type="number"
            name="Balance"
            value={formValues.Balance}
            onChange={setFormValues}
          />
          <InputForm
            label={`Entrada ${formatCurrentMonth(currentMonth)}`}
            type="number"
            name="EntryCurrentMonth"
            value={formValues.EntryCurrentMonth}
            onChange={setFormValues}
          />
          <C.ButtonContainer>
            <C.Button type="submit">Gerar Relatorio</C.Button>
          </C.ButtonContainer>
        </C.Form>
      </C.Container>
    </>
  );
}
