import { formatCurrentMonth } from "../../helpers/dateFilter";
import ResumeItem from "../ResumeItem";
import * as C from "./styles";

export default function InfoArea({currentMonth, onMonthChange, income, expense}) {

  function handlePrevMonth() {
    let [year, month] = currentMonth.split("-")
    let currentDate = new Date(parseInt(year), parseInt(month) - 1, 1)
    currentDate.setMonth(currentDate.getMonth() - 1)
    onMonthChange(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`)
  }

  function handleNextMonth() {
    let [year, month] = currentMonth.split("-")
    let currentDate = new Date(parseInt(year), parseInt(month) - 1, 1)
    currentDate.setMonth(currentDate.getMonth() + 1)
    onMonthChange(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`)
  }

  return (
    <C.Container>
        <C.MonthArea>
            <C.MonthArrow onClick={handlePrevMonth}>&larr;</C.MonthArrow>
            <C.MonthTitle>{formatCurrentMonth(currentMonth)}</C.MonthTitle>
            <C.MonthArrow onClick={handleNextMonth}>&rarr;</C.MonthArrow>
        </C.MonthArea>
        <C.ResumeArea>
            <ResumeItem title="Entrada" value={income}/>
            <ResumeItem title="Saida" value={expense}/>
            <ResumeItem title="Balanço" value={income - expense} color={(income-expense) < 0 ? 'red' : 'green'}/>
        </C.ResumeArea>
    </C.Container>
  )
}
