import styled from "styled-components";

export const ContainerTable = styled.div`
  overflow-y: auto;
  height: 500px;
  padding: 5px;
`

export const Table = styled.table`
  /* margin: 0 auto; */
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 10px;
`;

export const TableHeadColumn = styled.th`
  width: ${(props) => (props.width ? `${props.width}px` : "auto")};
  padding: 10px 5px;
  text-align: left;
`;