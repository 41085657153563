export const categories = {
  Agua: { title: "Agua", color: "#48D1CC", expense: true },
  Luz: { title: "Luz", color: "#F3D957", expense: true },
  Internet: { title: "Internet", color: "#808080", expense: true },
  Aluguel: { title: "Aluguel", color: "#000080", expense: true },
  Transportes: { title: "Transportes", color: "#FFA07A", expense: true },
  AssistenciaMKT: { title: "Assistência MKT", color: "#FFA500", expense: true },
  Alimentacao: { title: "Alimentação", color: "#ADFF2F", expense: true },
  Combustivel: { title: "Combustível", color: "#DAA520", expense: true },
  Aquisicoes: { title: "Aquisições", color: "#8A2BE2", expense: true },
  AssistenciaSocial: {
    title: "Assistência Social",
    color: "#F7CAAC",
    expense: true,
  },
  Missoes: { title: "Missões", color: "#FFD700", expense: true },
  Convencao: { title: "Convenção", color: "#808000", expense: true },
  Administrativo: { title: "Administrativo", color: "#C71585", expense: true },
  Servico: { title: "Serviços", color: "#C71510", expense: true },
  Dizimo: { title: "Dizimo", color: "#006400", expense: false },
  Ofertas: { title: "Ofertas", color: "#1E90FF", expense: false },
};

export const namesMission = {
  Joicy: { title: "Joicy", color: "#1E90FF", expense: true },
  Anderson: { title: "Anderson", color: "#2F4F4F", expense: true },
  Hector: { title: "Hector", color: "#4B0082", expense: true },
  Marcio: { title: "Marcio", color: "#40E0D0", expense: true },
  Claudinha: { title: "Claudinha", color: "#000080", expense: true },
  Marcia: { title: "Marcia", color: "#006400", expense: true },
  Tereza: { title: "Tereza", color: "#800080", expense: true },
  Célia: { title: "Célia", color: "#8B0000", expense: true },
  Elenizia: { title: "Elenizia", color: "#8B008B", expense: true },
  Camile: { title: "Camile", color: "#C71585", expense: true },
  Trino: { title: "Trino", color: "#DAA520", expense: true },
  Cristiane: { title: "Cristiane", color: "#DC143C", expense: true },
  AGE: { title: "AGE", color: "#A52A2A", expense: true },
  KAIROS: { title: "KAIROS", color: "#FF0000", expense: true },
  Outros: { title: "Outros", color: "#708090", expense: true },
};
