import styled from 'styled-components'

export const Container = styled.div``;

export const Header = styled.div`
    background-color: black;
    height: 150px;
    text-align: center;

    a {
        color: #FFF;
        text-decoration: none;
        text-transform: uppercase;
        transition: all .3s;
    }

    a:hover {
        text-decoration: underline;
        transform: scale(2, .5)
    }

    ul {
        padding-top: 30px;
        list-style: none;
        display: flex;
        gap: 150px;
        justify-content: center;
        align-items: center;
    }

    li:hover {
        transform: scale(1.1)
    }
`

export const HeaderText = styled.h1`
    margin: 0;
    padding: 0;
    color: #FFFFFF;
    padding-top: 30px;
`

export const Body = styled.div`
    margin: auto;
    max-width: 980px;
    margin-top: 60px;
`