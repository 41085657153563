import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
 0% {
   opacity: 0;
 }
 100% {
   opacity: 1;
 }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000;
  animation: ${fadeIn} 1s ease;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

export const LoginHeader = styled.div`
  width: 100%;
  padding: 20px 0;
  text-align: center;
`;

export const LoginTitle = styled.h1`
  font-size: 24px;
  color: #fff;
  margin-bottom: 5px;
`;

export const LoginSubTitle = styled.p`
  font-size: 16px;
  color: #fff;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

export const ImageContainer = styled.div`
  text-align: center;

  img {
    height: 110px;
    border-radius: 50%;
  }
`;


export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  svg {
    margin-right: 10px;
    font-size: 20px;
    color: #555;
  }
`;

export const TitleInput = styled.p`
  color: #000;
`;

export const LoginInput = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #000;
  }
`;

export const LoginButton = styled.button`
  background-color: #000;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 9px;

  &:hover {
    background-color: #333;
    transform: scale(1.05);
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
    transform: scale(1);
  }
`;

export const ErrorMessage = styled.p`
  color: #ff0000;
  margin-top: 10px;
  text-align: center;
  animation: ${fadeIn} 0.5s ease-in-out;
`;