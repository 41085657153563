import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #c2c2c2; */
`;

export const Form = styled.form`
  width: 40vw;
  background-color: #d4d4d4;
  padding: 50px;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 8px;
  @media (max-width: 1024px) {
    width: 70vw;
  }
`;

export const FormTitle = styled.h2`
  color: #222;
  font-size: 40px;
  padding-bottom: 30px;
`;

export const Section = styled.p`
  color: gray;
  font-size: 20px;
  padding-bottom: 30px;
  padding-top: 30px;
  font-weight: 700;
`;

export const InputLabel = styled.label`
    flex: 1;
    margin: 10px;
`

export const InputTitle = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`

export const Input = styled.input`
    width: 100%;
    height: 30px;
    padding: 0 5px;
    border: 1px solid lightblue;
    border-radius: 5px;
`

export const TextArea = styled.textarea`
    width: 100%;
    height: 80px;
    padding: 5px 5px;
    border: 1px solid lightblue;
    border-radius: 5px;
    resize: none;
`

export const ButtonContainer = styled.div`
  text-align: center;
  padding-top: 20px;
`;

export const Button = styled.button`
  cursor: pointer;
  background-color: #222;
  color: #FFF;
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 5px;
  &:hover {
    background-color: #C2C2C2;
  }
`;