import styled from "styled-components";

export const TableLine = styled.tr`
  border: 1px solid red;
`;

export const TableColumn = styled.td`
  padding: 10px 5px;
`;

export const Category = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  // display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  background-color: ${(props) => props.color};
`;

export const Value = styled.div`
  color: ${(props) => props.color};
`;

export const ActionButtons = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center; */
`;

export const EditButton = styled.button`
  margin-right: 8px;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  background-color: green;
  color: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  &:hover {
    background-color: #016301;
  }
`;
export const DeleteButton = styled.button`
  margin-right: 8px;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  background-color: red;
  color: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  &:hover {
    background-color: #b30000;
  }
`;
