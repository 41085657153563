import { useEffect, useState } from "react";
import InfoArea from "../../components/InfoArea";
import * as C from "./styles";
import { filterListByMonth, getCurrentMonth } from "../../helpers/dateFilter";
import { categories } from "../../data/categories";
import InputArea from "../../components/InputArea";
import TableArea from "../../components/TableArea";
import { db } from "../../firebase/config";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import Navbar from "../../components/Navbar";
import { toast } from "react-toastify";

export function Home() {
  const [lists, setLists] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(getCurrentMonth());
  const [income, setIncome] = useState(0);
  const [expense, setExpense] = useState(0);

  //NOVO
  const [isEditing, setIsEditing] = useState(false);
  const [editItem, setEditItem] = useState(null);

  useEffect(() => {
    //Tipo de GET que funciona tambem
    // async function allItems() {
    //   const querySnapshot = await getDocs(collection(db, "controlefinanceiro"));
    //   let list = [];
    //   querySnapshot.forEach((doc) => {
    //     var dados = doc.data();
    //     dados = {
    //       category: dados.category,
    //       value: dados.value,
    //       date: unixToDate(dados.date),
    //       title: dados.title,
    //     };
    //     list.push(dados);
    //   });
    //   setLists(list);
    // }
    // allItems();

    getUsers();
  }, []);

  function unixToDate(date) {
    var time = new Date(date.seconds * 1000);
    return time;
  }

  const getUsers = async () => {
    const data = await getDocs(collection(db, "controlefinanceiro"));
    const dataRef = data.docs.map((doc) => ({
      id: doc.id,
      category: doc.data().category,
      value: doc.data().value,
      date: unixToDate(doc.data().date),
      title: doc.data().title,
    }));
    setLists(dataRef);
  };

  useEffect(() => {
    setFilteredList(filterListByMonth(lists, currentMonth));
  }, [lists, currentMonth, filterListByMonth]);

  useEffect(() => {
    let incomeCount = 0;
    let expenseCount = 0;

    for (let i = 0; i < filteredList.length; i++) {
      if (categories[filteredList[i].category].expense) {
        expenseCount += filteredList[i].value;
      } else {
        incomeCount += filteredList[i].value;
      }
    }

    setIncome(incomeCount);
    setExpense(expenseCount);
  }, [filteredList]);

  function handleMonthChange(newMonth) {
    setCurrentMonth(newMonth);
  }

  function validateInput(item) {
    if (item.date === "") {
      toast.warn("Por favor preencha o campo data");
      return false;
    }
    if (isNaN(new Date(item.date).getTime())) {
      toast.warn("Data inválida");
      return false;
    }
    if (item.category === "") {
      toast.warn("Por favor preencha o campo categoria");
      return false;
    }
    if (item.title === "") {
      toast.warn("Por favor preencha o campo Título");
      return false;
    }
    if (item.value === "") {
      toast.warn("Por favor preencha o campo Valor");
      return false;
    }
    return true;
  }

  async function handleAddItem(item) {
    console.log(item);
    if (!validateInput(item)) {
      return;
    }
    try {
      await addDoc(collection(db, "controlefinanceiro"), {
        date: item.date,
        category: item.category,
        title: item.title,
        value: Number(item.value),
      });
      setLists([...lists, { ...item, value: Number(item.value) }]);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async function handleEditItem(item) {
    setIsEditing(true);
    setEditItem(item);
  }

  function handleCancelEdit() {
    setIsEditing(false);
    setEditItem(null);
  }

  async function handleUpdateItem(updatedItem) {
    try {
      await updateDoc(doc(db, "controlefinanceiro", updatedItem.id), {
        date: updatedItem.date,
        category: updatedItem.category,
        title: updatedItem.title,
        value: Number(updatedItem.value),
      });
      setLists((prevLists) =>
        prevLists.map((listItem) =>
          listItem.id === updatedItem.id
            ? { ...listItem, ...updatedItem }
            : listItem
        )
      );
      setIsEditing(false);
      setEditItem(null);
      toast.success("Item atualizado com sucesso!");
    } catch (e) {
      console.error("Erro ao atualizar o item: ", e);
    }
  }

  async function handleDeleteItem(item) {
    try {
      if (!item) {
        toast.error("Item inválido. Não foi possível excluir.");
        return;
      }

      await deleteDoc(doc(db, "controlefinanceiro", item.id));

      setLists((prevLists) =>
        prevLists.filter((listItem) => listItem.id !== item.id)
      );

      toast.success("Item excluído com sucesso!");
    } catch (e) {
      console.error("Erro ao excluir o item: ", e);
      toast.error("Erro ao excluir o item.");
    }
  }

  return (
    <C.Container>
      <Navbar  />
      <C.Body>
        <InfoArea
          currentMonth={currentMonth}
          onMonthChange={handleMonthChange}
          income={income}
          expense={expense}
        />

        <InputArea
          onAdd={handleAddItem}
          dateCategory={categories}
          isEditing={isEditing}
          editItem={editItem}
          onUpdateItem={handleUpdateItem}
          onCancelEdit={handleCancelEdit}
        />

        <TableArea
          list={filteredList}
          dateCategoryColor={categories}
          onEdit={handleEditItem}
          onDelete={handleDeleteItem}
        />
      </C.Body>
    </C.Container>
  );
}
