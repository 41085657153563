import React, { useContext, createContext, useEffect, useState } from 'react';
import { auth } from '../firebase/config.js';

const AuthContext = createContext();

export function AuthProvider({children, value}) {
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};