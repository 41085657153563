import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatCurrentMonth, formatMonthPrevious } from "./dateFilter";

export default async function generatePdfFinanceiro(list, currentMonth) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const reportTitle = [
    {
      text: "RELATÓRIO GERENCIAL LÍRIO DOS VALES",
      fontSize: 15,
      bold: true,
      margin: [15, 20, 0, 45],
    },
  ];

  const dados = new Array(list);
  const financeiro1 = dados.map((element) => {
    return [{ text: element.Water, fontSize: 9, margin: [0, 2, 0, 2] }];
  });
  const financeiro2 = dados.map((element) => {
    return [{ text: element.Light, fontSize: 9, margin: [0, 2, 0, 2] }];
  });
  const financeiro3 = dados.map((element) => {
    return [
      { text: element.InternetAndSofts, fontSize: 9, margin: [0, 2, 0, 2] },
    ];
  });
  const financeiro4 = dados.map((element) => {
    return [{ text: element.Rent, fontSize: 9, margin: [0, 2, 0, 2] }];
  });
  const financeiro5 = dados.map((element) => {
    return [{ text: element.Transport, fontSize: 9, margin: [0, 2, 0, 2] }];
  });
  const financeiro6 = dados.map((element) => {
    return [{ text: element.MKT, fontSize: 9, margin: [0, 2, 0, 2] }];
  });
  const financeiro7 = dados.map((element) => {
    return [{ text: element.Food, fontSize: 9, margin: [0, 2, 0, 2] }];
  });
  const financeiro8 = dados.map((element) => {
    return [{ text: element.Fuel, fontSize: 9, margin: [0, 2, 0, 2] }];
  });
  const financeiro9 = dados.map((element) => {
    return [{ text: element.Acquisitions, fontSize: 9, margin: [0, 2, 0, 2] }];
  });
  const financeiro10 = dados.map((element) => {
    return [
      {
        text: element.Social,
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
    ];
  });
  const financeiro11 = dados.map((element) => {
    return [
      {
        text: element.Missions,
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
    ];
  });
  const financeiro12 = dados.map((element) => {
    return [
      {
        text: element.Convention,
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
    ];
  });
  const financeiro13 = dados.map((element) => {
    return [
      {
        text: element.Administrative,
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
    ];
  });

  const observacao = dados.map((elements) => {
    return [
      { text: elements.totalExit, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: elements.EntryMonthPrevious, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: elements.Balance, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: elements.EntryCurrentMonth, fontSize: 9, margin: [0, 2, 0, 2] },
    ];
  });

  const details = [
    {
      text: `Mês Referencia: ${formatCurrentMonth(currentMonth)}`,
      style: "subheader",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [{ text: "Agua", style: "tableHeader", fontSize: 10 }],
          ...financeiro1,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [{ text: "Luz", style: "tableHeader", fontSize: 10 }],
          ...financeiro2,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [{ text: "Internet e Softs", style: "tableHeader", fontSize: 10 }],
          ...financeiro3,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [{ text: "Aluguel", style: "tableHeader", fontSize: 10 }],
          ...financeiro4,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [{ text: "Transportes", style: "tableHeader", fontSize: 10 }],
          ...financeiro5,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [{ text: "MKT", style: "tableHeader", fontSize: 10 }],
          ...financeiro6,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [{ text: "Alimentação", style: "tableHeader", fontSize: 10 }],
          ...financeiro7,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [
            {
              text: "Combustível",
              style: "tableHeader",
              fontSize: 10,
            },
          ],
          ...financeiro8,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [
            {
              text: "Aquisições",
              style: "tableHeader",
              fontSize: 10,
            },
          ],
          ...financeiro9,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [
            {
              text: "Social",
              style: "tableHeader",
              fontSize: 10,
            },
          ],
          ...financeiro10,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [
            {
              text: "Missões",
              style: "tableHeader",
              fontSize: 10,
            },
          ],
          ...financeiro11,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [
            {
              text: "Convenção",
              style: "tableHeader",
              fontSize: 10,
            },
          ],
          ...financeiro12,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [
          [
            {
              text: "Administrativo",
              style: "tableHeader",
              fontSize: 10,
            },
          ],
          ...financeiro13,
        ],
      },
    },

    {
      text: "",
      style: "titleTable",
    },

    {
      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*"],
        body: [
          [
            { text: "Total de Saída", style: "tableHeader", fontSize: 10 },
            {
              text: `Entrada ${formatMonthPrevious(currentMonth)}`,
              style: "tableHeader",
              fontSize: 10,
            },
            { text: "Saldo", style: "tableHeader", fontSize: 10 },
            {
              text: `Entrada ${formatCurrentMonth(currentMonth)}`,
              style: "tableHeader",
              fontSize: 10,
            },
          ],
          ...observacao,
        ],
      },
    },
  ];

  const docDefinitions = {
    info: {
      title: `RELATÓRIO GERENCIAL LÍRIO DOS VALES - ${formatCurrentMonth(
        currentMonth
      )} `,
    },
    pageOrientation: "portrait",
    styles: {
      header: {
        fontSize: 15,
        bold: true,
        margin: [15, 0, 20, 45],
      },
      subheader: {
        fontSize: 13,
        bold: true,
        alignment: "center",
      },
      subitem: {
        margin: [0, 8, 0, 8],
        decoration: "underline",
        decorationColor: "#000",
      },
      subitem2: {
        margin: [0, 0, 0, 8],
        decoration: "underline",
        decorationColor: "#000",
      },
      titleTable: {
        margin: [0, 8, 0, 8],
        fontSize: 13,
        bold: true,
        alignment: "center",
        decoration: "underline",
        decorationColor: "#000",
      },
    },

    pageSize: "A4",
    pageMargins: [15, 50, 15, 40],

    header: [reportTitle],
    content: [details],
  };

  pdfMake.createPdf(docDefinitions).open();
}
