import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDKKSdXx6D4PlHsFYL3hOuDA2Scs2c1bHk",
  authDomain: "lirioigreja-8862d.firebaseapp.com",
  projectId: "lirioigreja-8862d",
  storageBucket: "lirioigreja-8862d.appspot.com",
  messagingSenderId: "924041675249",
  appId: "1:924041675249:web:5ea01f5f7300e99b42c450"
};

let app;
try {
  app = initializeApp(firebaseConfig);
} catch (error) {
  console.error("Erro ao inicializar o Firebase:", error);
}

const auth = getAuth(app);
const db = getFirestore(app);

export { db, auth, signInWithEmailAndPassword, onAuthStateChanged, signOut };