import styled from "styled-components";

export const InputLabel = styled.label`
    flex: 1;
    margin: 10px;
`

export const InputTitle = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`

export const Input = styled.input`
    width: 100%;
    height: 30px;
    padding: 0 5px;
    border: 1px solid lightblue;
    border-radius: 5px;
`