import { useState } from "react";
import InputForm from "../../components/InputForm/index";
import generatePdf from "../../helpers/generatePdf";
import * as C from "./styles";
import { getCurrentMonth } from "../../helpers/dateFilter";
import Navbar from "../../components/Navbar";

export default function Relatorio() {
  const [currentMonth] = useState(getCurrentMonth());

  const [formValues, setFormValues] = useState({
    numberGoersTotal: 0,
    numberGoersCultsWeeks: 0,
    numberGoersCultsSunday: 0,
    numberVisitorMonth: 0,
    numberMembersTotal: 0,
    numberBaptizedMonth: 0,
    numberReconciliationMonth: 0,
    numberAcceptance: 0,
    numberShutdowns: 0,
    balancePrevious: 0,
    titheReceived: 0,
    offersReceived: 0,
    extrasReceived: 0,
    totalProhibited: 0,
    totalExit: 0,
    balanceCurrent: 0,
    numberDecimistMonth: 0,
    contributionConvention: 0,
    dataDepositAccomplished: 0,
    observation: "",
  });

  function onSubmit() {
    generatePdf(formValues, currentMonth);
  }

  return (
    <>
      <Navbar />
      <C.Container>
        <C.Form
          onSubmit={(ev) => {
            ev.preventDefault();
            onSubmit();
          }}
        >
          <C.FormTitle>Relatorio</C.FormTitle>
          <C.Section>MEMBRESIA</C.Section>

          <InputForm
            label="Número frequentadores total"
            type="number"
            name="numberGoersTotal"
            value={formValues.numberGoersTotal}
            onChange={setFormValues}
          />
          <InputForm
            label="Número frequentadores cultos semanais"
            type="number"
            name="numberGoersCultsWeeks"
            value={formValues.numberGoersCultsWeeks}
            onChange={setFormValues}
          />
          <InputForm
            label="Número de frequentadores culto Dominical"
            type="number"
            name="numberGoersCultsSunday"
            value={formValues.numberGoersCultsSunday}
            onChange={setFormValues}
          />
          <InputForm
            label="Número de visitantes do mês"
            type="number"
            name="numberVisitorMonth"
            value={formValues.numberVisitorMonth}
            onChange={setFormValues}
          />
          <InputForm
            label="Número de membros total"
            type="number"
            name="numberMembersTotal"
            value={formValues.numberMembersTotal}
            onChange={setFormValues}
          />
          <InputForm
            label="Número de batizados no mês"
            type="number"
            name="numberBaptizedMonth"
            value={formValues.numberBaptizedMonth}
            onChange={setFormValues}
          />
          <InputForm
            label="Número de reconciliações"
            type="number"
            name="numberReconciliationMonth"
            value={formValues.numberReconciliationMonth}
            onChange={setFormValues}
          />
          <InputForm
            label="Número de aceitações"
            type="number"
            name="numberAcceptance"
            value={formValues.numberAcceptance}
            onChange={setFormValues}
          />
          <InputForm
            label="Número de desligamentos"
            type="number"
            name="numberShutdowns"
            value={formValues.numberShutdowns}
            onChange={setFormValues}
          />

          <C.Section>FINANCEIRO</C.Section>

          <InputForm
            label="Saldo Anterior"
            type="number"
            name="balancePrevious"
            value={formValues.balancePrevious}
            onChange={setFormValues}
          />
          <InputForm
            label="Dízimos recebidos"
            type="number"
            name="titheReceived"
            value={formValues.titheReceived}
            onChange={setFormValues}
          />
          <InputForm
            label="Ofertas recebidas"
            type="number"
            name="offersReceived"
            value={formValues.offersReceived}
            onChange={setFormValues}
          />
          <InputForm
            label="Extras recebidos"
            type="number"
            name="extrasReceived"
            value={formValues.extrasReceived}
            onChange={setFormValues}
          />
          <InputForm
            label="Total de entrada"
            type="number"
            name="totalProhibited"
            value={formValues.totalProhibited}
            onChange={setFormValues}
          />
          <InputForm
            label="Total de Saída"
            type="number"
            name="totalExit"
            value={formValues.totalExit}
            onChange={setFormValues}
          />
          <InputForm
            label="Saldo Atual"
            type="number"
            name="balanceCurrent"
            value={formValues.balanceCurrent}
            onChange={setFormValues}
          />
          <InputForm
            label="Número de dizimistas no mês"
            type="number"
            name="numberDecimistMonth"
            value={formValues.numberDecimistMonth}
            onChange={setFormValues}
          />
          <InputForm
            label="Contribuição Convenção"
            type="number"
            name="contributionConvention"
            value={formValues.contributionConvention}
            onChange={setFormValues}
          />
          <InputForm
            label="Data do depósito realizado"
            type="date"
            name="dataDepositAccomplished"
            value={formValues.dataDepositAccomplished}
            onChange={setFormValues}
          />
          <InputForm
            label="OBSERVAÇÕES"
            type="text"
            name="observation"
            value={formValues.observation}
            onChange={setFormValues}
          />
          <C.ButtonContainer>
            <C.Button type="submit">Gerar Relatorio</C.Button>
          </C.ButtonContainer>
        </C.Form>
      </C.Container>
    </>
  );
}
