import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  width: 100%;
`;

export const Items = styled.div`
  margin: 15px 2px 15px 2px;
  /* background-color: #8ca0ff; */
`;

export const Titulo = styled.h1`
  font-size: 18px;
  color: #000000;
  text-align: center;
  margin: 5px 0 5px 0;
`;