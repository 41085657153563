import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Graficos from "./pages/Graficos";
import { Home } from "./pages/Home";
import Relatorio from "./pages/Relatorios";
import { ThemeProvider } from "styled-components";
import Missoes from "./pages/Missoes";
import Financeiro from "./pages/Financeiro";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login";
import { auth, onAuthStateChanged } from "./firebase/config.js";
import { useState, useEffect } from "react";

import { AuthProvider } from "./hooks/AuthProvider";

export function App() {
  const [user, setUser] = useState(undefined);

  const loadingUser = user === undefined;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, [auth]);

  if (loadingUser) {
    return <p>Carregando...</p>;
  }

  const theme = {
    active: "#000",
    white: "#FFF",
  };
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider value={{ user }}>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={!user ? <Login /> : <Home />} />
            <Route
              path="/financeiro"
              element={!user ? <Login /> : <Financeiro />}
            />
            <Route path="/missao" element={!user ? <Login /> : <Missoes />} />
            <Route
              path="/graficos"
              element={!user ? <Login /> : <Graficos />}
            />
            <Route
              path="/relatorio"
              element={!user ? <Login /> : <Relatorio />}
            />
          </Routes>
        </Router>
      </AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </ThemeProvider>
  );
}
