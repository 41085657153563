export function getCurrentMonth() {
  let now = new Date();
  return `${now.getFullYear()}-${now.getMonth() + 1}`;
}

export function filterListByMonth(list, date) {
  let newList = [];
  let [year, month] = date.split("-");

  for (let i in list) {
    if (
      list[i].date?.getFullYear() === parseInt(year) &&
      list[i].date?.getMonth() + 1 === parseInt(month)
    ) {
      newList.push(list[i]);
    }
  }
  return newList;
}

export function formatDate(date) {
  // console.log(date);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  return `${addZeroToDate(day)}/${addZeroToDate(month)}/${year}`;
}

const addZeroToDate = (n) => (n < 10 ? `0${n}` : `${n}`);

export function formatCurrentMonth(currentMonth) {
  let [year, month] = currentMonth.split("-");
  let months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  return `${months[parseInt(month) - 1]} de ${year}`;
}

export function formatMonthPrevious(currentMonth) {
  let [year, month] = currentMonth.split("-");
  let months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  return `${months[parseInt(month) - 2]} de ${year}`;
}

export function newDateAdjusted(dateField) {
  let [year, month, day] = dateField.split("-");

  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}

export  function converterData(data) {
  // Divide a data em ano, mês e dia
  const [ano, mes, dia] = data.split('-');

  // Formata a data no formato desejado
  const dataFormatada = `${dia}/${mes}/${ano}`;

  return dataFormatada;
}