import TableItem from "../TableItem";
import * as C from "./styles";

export default function TableArea({
  list,
  dateCategoryColor,
  onEdit,
  onDelete,
}) {
  return (
    <>
      <C.ContainerTable>
        <C.Table>
          <thead className="cabecalho-table">
            <tr>
              <C.TableHeadColumn width={100}>Data</C.TableHeadColumn>
              <C.TableHeadColumn width={130}>Categoria</C.TableHeadColumn>
              <C.TableHeadColumn>Titulo</C.TableHeadColumn>
              <C.TableHeadColumn width={150}>Valor</C.TableHeadColumn>
              <C.TableHeadColumn >Editar/Excluir</C.TableHeadColumn>
            </tr>
          </thead>

          <tbody>
            {list.map((item, index) => (
              <TableItem
                key={index}
                item={item}
                dateColor={dateCategoryColor}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))}
          </tbody>
        </C.Table>
      </C.ContainerTable>
    </>
  );
}
