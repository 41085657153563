import * as C from "./styles";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { auth, signInWithEmailAndPassword } from "../../firebase/config.js";
import { AiOutlineMail, AiOutlineLock } from 'react-icons/ai';
import logoLirio from '../../assets/img/lirioigreja.png'

const Icon = ({ icon: IconComponent, title: TitleComponent }) => (
  <C.IconContainer>
    <IconComponent />
    <C.TitleInput>{TitleComponent}</C.TitleInput>
  </C.IconContainer>
);

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
      navigate("/");
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      setError("Credenciais inválidas. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <C.LoginContainer>
      <C.LoginHeader>
        <C.LoginTitle>Bem vindo ao sistema financeiro</C.LoginTitle>
        <C.LoginSubTitle>Entre com a sua conta</C.LoginSubTitle>
      </C.LoginHeader>

      <C.LoginForm onSubmit={handleSubmit(onSubmit)}>
        <C.ImageContainer>
          <img src={logoLirio} alt="logo lirio" />
        </C.ImageContainer>
      <Icon icon={AiOutlineMail} title="E-mail" />
        <C.LoginInput
          type="email"
          placeholder="Email"
          {...register("email", { required: "E-mail é obrigatório" })}
        />
         {errors.email && <C.ErrorMessage>{errors.email.message}</C.ErrorMessage>}
         <Icon icon={AiOutlineLock} title="Senha"/>
        <C.LoginInput
          type="password"
          placeholder="Senha"
          {...register("password", { required: "Senha é obrigatório" })}
        />
        {errors.password && <C.ErrorMessage>{errors.password.message}</C.ErrorMessage>}
        <C.LoginButton type="submit" disabled={loading}>
          {loading ? "Carregando..." : "Entrar"}
        </C.LoginButton>
      </C.LoginForm>

      {error && (
        <C.ErrorMessage>Credenciais inválidas. Tente novamente.</C.ErrorMessage>
      )}
    </C.LoginContainer>
  );
};

export default Login;
