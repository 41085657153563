import * as C from "./styles";
import { NavLink } from "react-router-dom";
import { auth, signOut } from '../../firebase/config.js';

import React from "react";
import { useAuth } from "../../hooks/AuthProvider";

const logout = () => {
  signOut(auth)
}

export default function Navbar(data) {
  const { user } = useAuth()

  return (
    <C.Nav>
      <C.TitleNav className="brand">Lirio Parelheiros</C.TitleNav>
      <C.LinkList>
        <C.LinkListItem>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Home
          </NavLink>
        </C.LinkListItem>
        <C.LinkListItem>
          <NavLink
            to="/financeiro"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Financeiro
          </NavLink>
        </C.LinkListItem>
        <C.LinkListItem>
          <NavLink
            to="/missao"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Missões
          </NavLink>
        </C.LinkListItem>
        <C.LinkListItem>
          <NavLink
            to="/relatorio"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Relatorio
          </NavLink>
        </C.LinkListItem>
        <C.LinkListItem>
          <NavLink
            to="/graficos"
            // state={data}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Graficos
          </NavLink>
        </C.LinkListItem>
       {user && (
         <C.LinkListItem>
         <C.SignOut onClick={logout}>
           Sair
         </C.SignOut>
       </C.LinkListItem>
       )}
      </C.LinkList>
    </C.Nav>
  );
}
