import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { converterData, formatCurrentMonth} from "./dateFilter";

export default async function generatePdf(list, currentMonth) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const reportTitle = [
    {
      text: "RELATÓRIO GERENCIAL LÍRIO DOS VALES",
      fontSize: 15,
      bold: true,
      margin: [15, 20, 0, 45],
    },
  ];

  const dados = new Array(list);

  const membresia = dados.map((element) => {
    return [
      { text: element.numberGoersTotal, fontSize: 9, margin: [0, 2, 0, 2] },
      {
        text: element.numberGoersCultsWeeks,
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      {
        text: element.numberGoersCultsSunday,
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      { text: element.numberVisitorMonth, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: element.numberMembersTotal, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: element.numberBaptizedMonth, fontSize: 9, margin: [0, 2, 0, 2] },
      {
        text: element.numberReconciliationMonth,
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      { text: element.numberAcceptance, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: element.numberShutdowns, fontSize: 9, margin: [0, 2, 0, 2] },
    ];
  });

  const financeiro = dados.map((elements) => {
    return [
      { text: elements.balancePrevious, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: elements.titheReceived, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: elements.offersReceived, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: elements.extrasReceived, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: elements.totalProhibited, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: elements.totalExit, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: elements.balanceCurrent, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: elements.numberDecimistMonth, fontSize: 9, margin: [0, 2, 0, 2] },
      {
        text: elements.contributionConvention,
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      {
        text: converterData(elements.dataDepositAccomplished),
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
    ];
  });

  const observacao = dados.map((elements) => {
    const observacaoRefatorada = elements.observation === "" ? "Não possui observação" : elements.observation
    return [{ text: observacaoRefatorada, fontSize: 9, margin: [0, 2, 0, 2] }];
  });

  const details = [
    {
      text: `Mês Referencia: ${formatCurrentMonth(currentMonth)}`,
      style: "subheader",
    },
    {
      text: "Igreja local: Lírio Parelheiros",
      style: "subitem",
    },
    {
      text: "Dirigente: Melquesedeque Siqueira",
      style: "subitem2",
    },
    {
      text: "Membresia",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*"],
        body: [
          [
            {
              text: "Número frequentadores total",
              style: "tableHeader",
              fontSize: 9,
            },
            {
              text: "Número frequentadores cultos semanais",
              style: "tableHeader",
              fontSize: 9,
            },
            {
              text: "Número de frequentadores culto Dominical",
              style: "tableHeader",
              fontSize: 9,
            },
            {
              text: "Número de visitantes do mês",
              style: "tableHeader",
              fontSize: 9,
            },
            {
              text: "Número de membros total",
              style: "tableHeader",
              fontSize: 9,
            },
            {
              text: "Número de batizados no mês",
              style: "tableHeader",
              fontSize: 9,
            },
            {
              text: "Número de reconciliações",
              style: "tableHeader",
              fontSize: 9,
            },
            { text: "Número de aceitações", style: "tableHeader", fontSize: 9 },
            {
              text: "Número de desligamentos",
              style: "tableHeader",
              fontSize: 9,
            },
          ],
          ...membresia,
        ],
      },
    },
    {
      text: "Financeiro",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
        body: [
          [
            { text: "Saldo Anterior", style: "tableHeader", fontSize: 10 },
            { text: "Dízimos recebidos", style: "tableHeader", fontSize: 10 },
            { text: "Ofertas recebidas", style: "tableHeader", fontSize: 10 },
            { text: "Extras recebidos", style: "tableHeader", fontSize: 10 },
            { text: "Total de entrada", style: "tableHeader", fontSize: 10 },
            { text: "Total de Saída", style: "tableHeader", fontSize: 10 },
            { text: "Saldo Atual", style: "tableHeader", fontSize: 10 },
            {
              text: "Número de dizimistas no mês",
              style: "tableHeader",
              fontSize: 10,
            },
            {
              text: "Contribuição Convenção",
              style: "tableHeader",
              fontSize: 10,
            },
            {
              text: "Data do depósito realizado",
              style: "tableHeader",
              fontSize: 10,
            },
          ],
          ...financeiro,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      table: {
        headerRows: 1,
        widths: [802],
        margin: [0, 5, 0, 15],
        body: [
          [{ text: "OBSERVAÇÕES", style: "tableHeader", fontSize: 10 }],
          ...observacao,
        ],
      },
    },
    {
      text: "",
      style: "titleTable",
    },
    {
      text: "Secretária Local: Cristiano Cavalcante",
      style: "subitem",
    },
    {
      text: "Dirigente Local: Melquesedeque Siqueira",
      style: "subitem2",
    },
  ];

  const rodape = [
    {
      text: "CONVENÇÃO DAS IGREJAS LÍRIO DOS VALES",
      style: "header",
    },
  ];

  const docDefinitions = {
    info: {
      title: `RELATÓRIO GERENCIAL LÍRIO DOS VALES - ${formatCurrentMonth(currentMonth)} `
    },
    pageOrientation: "landscape",
    styles: {
      header: {
        fontSize: 15,
        bold: true,
        margin: [15, 0, 20, 45],
      },
      subheader: {
        fontSize: 13,
        bold: true,
        alignment: "center",
      },
      subitem: {
        margin: [0, 8, 0, 8],
        decoration: "underline",
        decorationColor: "#000",
      },
      subitem2: {
        margin: [0, 0, 0, 8],
        decoration: "underline",
        decorationColor: "#000",
      },
      titleTable: {
        margin: [0, 8, 0, 8],
        fontSize: 13,
        bold: true,
        alignment: "center",
        decoration: "underline",
        decorationColor: "#000",
      },
    },

    pageSize: "A4",
    pageMargins: [15, 50, 15, 40],

    header: [reportTitle],
    content: [details],
    footer: [rodape],
  };

  pdfMake.createPdf(docDefinitions).open();
}
