import { useEffect, useState } from "react";
// import { categories } from "../../data/categories";
import { newDateAdjusted } from "../../helpers/dateFilter";
import * as C from "./styles";

export default function InputArea({
  onAdd,
  dateCategory,
  isEditing,
  editItem,
  onUpdateItem,
  onCancelEdit,
}) {
  const [dateField, setDateField] = useState("");
  const [categoryField, setCategoryField] = useState("");
  const [titleField, setTitleField] = useState("");
  const [valueField, setValueField] = useState(0);

  let categoryKeys = Object.keys(dateCategory);

  useEffect(() => {
    if (isEditing && editItem && editItem.date) {
      setDateField(editItem.date.toISOString().split("T")[0]);
      setCategoryField(editItem.category);
      setTitleField(editItem.title);
      setValueField(editItem.value);
    } else {
      clearFields();
    }
  }, [isEditing, editItem]);

  function clearFields() {
    setDateField("");
    setCategoryField("");
    setTitleField("");
    setValueField(0);
  }

  async function handleAddEvent() {
    if (!isEditing) {
      onAdd({
        date: newDateAdjusted(dateField),
        category: categoryField,
        title: titleField,
        value: valueField,
      });
      clearFields();
    } else {
      onUpdateItem({
        id: editItem.id,
        date: newDateAdjusted(dateField),
        category: categoryField,
        title: titleField,
        value: valueField,
      });
      onCancelEdit();
    }
  }

  return (
    <C.Container>
      <C.InputLabel>
        <C.InputTitle>Data</C.InputTitle>
        <C.Input
          type="date"
          value={dateField}
          onChange={(e) => setDateField(e.target.value)}
        />
      </C.InputLabel>

      <C.InputLabel>
        <C.InputTitle>Categoria</C.InputTitle>
        <C.Select
          type="date"
          value={categoryField}
          onChange={(e) => setCategoryField(e.target.value)}
        >
          <option></option>
          {categoryKeys.map((key, index) => (
            <option key={index} value={key}>
              {dateCategory[key].title}
            </option>
          ))}
        </C.Select>
      </C.InputLabel>

      <C.InputLabel>
        <C.InputTitle>Titulo</C.InputTitle>
        <C.Input
          type="text"
          value={titleField}
          onChange={(e) => setTitleField(e.target.value)}
        />
      </C.InputLabel>

      <C.InputLabel>
        <C.InputTitle>Valor</C.InputTitle>
        <C.Input
          type="number"
          value={valueField}
          onChange={(e) => setValueField(e.target.value)}
        />
      </C.InputLabel>

      <C.InputLabel>
        <C.InputTitle>&nbsp;</C.InputTitle>
        <C.Button onClick={handleAddEvent}>
          {/* {" "} */}
          {isEditing ? "Atualizar" : "Adicionar"}
        </C.Button>
      </C.InputLabel>

      {isEditing && (
        <C.InputLabel>
          <C.InputTitle>&nbsp;</C.InputTitle>
          <C.CancelButton onClick={onCancelEdit}>Cancelar</C.CancelButton>
        </C.InputLabel>
      )}
    </C.Container>
  );
}
