import { InputLabel, InputTitle, Input } from "./styles";

export default function InputForm(props) {
  return (
    <InputLabel>
      <InputTitle>{props.label}:</InputTitle>
      <Input
        type={props.type}
        name={props.name}
        value={props.value}
        onChange={(ev) => {
          props.onChange((previousStates) => ({
            ...previousStates,
            [props.name]: ev.target.value,
          }));
        }}
      />
    </InputLabel>
  );
}
