import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.15) 0px -2px 10px 0px;
  justify-content: space-between;
  align-items: center;
  padding: 0.8em 2em;
  background-color: #000;

  .active {
    background-color: #fff;
    color: #000;
  }
`;

export const TitleNav = styled.h1`
  font-size: 1.2em;
  color: #fff;
`;

export const LinkList = styled.ul`
  display: flex;
  list-style: none;

  li a {
    padding: 0.4em 0.6em;
  }
`;

export const LinkListItem = styled.li`
  margin-right: 1em;
`;

export const SignOut = styled.button`
  color: #000000;
  background: #fff;
  padding: 2px 1em;
  border-radius: 5px;

  :hover {
    color: #000;
  background: #ccc;
  }
`;