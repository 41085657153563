import * as C from "./styles";
import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { db } from "../../firebase/config";
import { collection, getDocs } from "firebase/firestore";
import { Chart, LinearScale, Tooltip } from "chart.js/auto";
import Navbar from "../../components/Navbar";
import { formatCurrentMonth, getCurrentMonth } from "../../helpers/dateFilter";

const ChartsPage = () => {
  const [data, setData] = useState({});
  const chartRef = useRef(null);
  const [valueCurrentYears, setValueCurrentYears] = useState("");
  const [currentMonthFormat] = useState(getCurrentMonth());

  useEffect(() => {
    Chart.register(LinearScale, Tooltip);
    fetchData();
  }, []);

  async function fetchData() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYears = currentDate.getFullYear();
    setValueCurrentYears(currentYears)

    try {
      const [dataRef, dataRefMission] = await Promise.all([
        collection(db, "controlefinanceiro"),
        collection(db, "missoes-lirio"),
      ]);

      const [querySnapshot, querySnapshotMisson] = await Promise.all([
        getDocs(dataRef),
        getDocs(dataRefMission),
      ]);

      if (querySnapshot.empty) {
        console.log("Nenhum dado encontrado no Firestore.");
        return;
      }

      if (querySnapshotMisson.empty) {
        console.log("Nenhum dado de missoes foi encontrado no Firestore.");
        return;
      }

      const categoryData = {};
      const filteredData = {};
      const titheAndOfferingsData = {};
      const titheAndOfferingsDataMonth = {};
      const categoryDataMission = {};
      const filteredDataMissionMonth = {};

      querySnapshotMisson.forEach((docMission) => {
        const itemMission = docMission.data();
        const { category, value, date } = itemMission;

        //Converte a data para o formato UTC e pega o mês atual
        const itemDate = new Date(itemMission.date.seconds * 1000);
        const itemMonth = itemDate.getMonth() + 1;
        const itemYears = itemDate.getFullYear();

        if (!category || !value || !date) {
          console.log(
            "Dados inválidos no documento:",
            docMission.id,
            itemMission
          );
          return;
        }

        if (itemYears === currentYears) {
          if (categoryDataMission[category]) {
            categoryDataMission[category] += value;
          } else {
            categoryDataMission[category] = value;
          }
        }

        if (itemMonth === currentMonth) {
          if (filteredDataMissionMonth[category]) {
            filteredDataMissionMonth[category] += value;
          } else {
            filteredDataMissionMonth[category] = value;
          }
        }
      });

      querySnapshot.forEach((doc) => {
        const item = doc.data();
        const { category, value, date } = item;

        if (!category || !value || !date) {
          console.log("Dados inválidos no documento:", doc.id, item);
          return;
        }

        //Converte a data para o formato UTC e pega o mês atual
        const itemDate = new Date(item.date.seconds * 1000);
        const itemMonth = itemDate.getMonth() + 1;
        const itemYears = itemDate.getFullYear();

        //Retorna o total
        if (itemYears === currentYears) {
          if (categoryData[category]) {
            categoryData[category] += value;
          } else {
            categoryData[category] = value;
          }
        }

        //Retorna somente o mensal
        if (itemMonth === currentMonth) {
          if (filteredData[category]) {
            filteredData[category] += value;
          } else {
            filteredData[category] = value;
          }
        }

        //Retorna o total
        if (itemYears === currentYears) {
          if (category === "Dizimo" || category === "Ofertas") {
            if (titheAndOfferingsData[category]) {
              titheAndOfferingsData[category] += value;
            } else {
              titheAndOfferingsData[category] = value;
            }
          }
        }

        //Retorna somente o mensal
        if (itemMonth === currentMonth) {
          if (category === "Dizimo" || category === "Ofertas") {
            if (titheAndOfferingsDataMonth[category]) {
              titheAndOfferingsDataMonth[category] += value;
            } else {
              titheAndOfferingsDataMonth[category] = value;
            }
          }
        }
      });

      const otherCategoriesData = Object.keys(categoryData)
        .filter((category) => category !== "Dizimo" && category !== "Ofertas")
        .reduce((obj, key) => {
          obj[key] = categoryData[key];
          return obj;
        }, {});

      const otherCategoriesDataMonth = Object.keys(filteredData)
        .filter((category) => category !== "Dizimo" && category !== "Ofertas")
        .reduce((obj, key) => {
          obj[key] = filteredData[key];
          return obj;
        }, {});

      if (chartRef.current) {
        chartRef.current.update();
      }

      const chartData = {
        labels: Object.keys(categoryData),
        datasets: [
          {
            label: `Total por Categoria de ${currentYears}`,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(75,192,192,0.4)",
            hoverBorderColor: "rgba(75,192,192,1)",
            data: Object.values(categoryData),
          },
        ],
      };

      const chartDataMensal = {
        labels: Object.keys(filteredData),
        datasets: [
          {
            label: `Total por Categoria de ${formatCurrentMonth(currentMonthFormat)}`,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(75,192,192,0.4)",
            hoverBorderColor: "rgba(75,192,192,1)",
            data: Object.values(filteredData),
          },
        ],
      };

      const titheAndOfferingsChartData = {
        labels: Object.keys(titheAndOfferingsData),
        datasets: [
          {
            label: `Gráfico Entradas de ${currentYears}`,
            backgroundColor: "rgba(255,99,132,0.2)",
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: Object.values(titheAndOfferingsData),
          },
        ],
      };

      const titheAndOfferingsChartDataMonth = {
        labels: Object.keys(titheAndOfferingsDataMonth),
        datasets: [
          {
            label: `Gráfico Entradas de ${formatCurrentMonth(currentMonthFormat)}`,
            backgroundColor: "rgba(255,99,132,0.2)",
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: Object.values(titheAndOfferingsDataMonth),
          },
        ],
      };

      const otherCategoriesChartData = {
        labels: Object.keys(otherCategoriesData),
        datasets: [
          {
            label: `Gráfico Saídas de ${currentYears}`,
            backgroundColor: "rgba(255,165,0,0.2)",
            borderColor: "rgba(255,165,0,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255,165,0,0.4)",
            hoverBorderColor: "rgba(255,165,0,1)",
            data: Object.values(otherCategoriesData),
          },
        ],
      };

      const otherCategoriesChartDataMonth = {
        labels: Object.keys(otherCategoriesDataMonth),
        datasets: [
          {
            label: `Gráfico Saídas de ${formatCurrentMonth(currentMonthFormat)}`,
            backgroundColor: "rgba(255,165,0,0.2)",
            borderColor: "rgba(255,165,0,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255,165,0,0.4)",
            hoverBorderColor: "rgba(255,165,0,1)",
            data: Object.values(otherCategoriesDataMonth),
          },
        ],
      };

      const chartDataMission = {
        labels: Object.keys(categoryDataMission),
        datasets: [
          {
            label: `Total Missão de ${currentYears}`,
            backgroundColor: "#6cc04b33",
            borderColor: "#4bc05b",
            borderWidth: 1,
            hoverBackgroundColor: "#6cc04b66",
            hoverBorderColor: "#4bc05b",
            data: Object.values(categoryDataMission),
          },
        ],
      };

      const chartDataMissionMonth = {
        labels: Object.keys(filteredDataMissionMonth),
        datasets: [
          {
            label: `Total Missão de ${formatCurrentMonth(currentMonthFormat)}`,
            backgroundColor: "#6cc04b33",
            borderColor: "#4bc05b",
            borderWidth: 1,
            hoverBackgroundColor: "#6cc04b66",
            hoverBorderColor: "#4bc05b",
            data: Object.values(filteredDataMissionMonth),
          },
        ],
      };

      chartData.labels = chartData.labels.map((label, index) => {
        const value = chartData.datasets[0].data[index];
        const total = chartData.datasets[0].data.reduce((a, b) => a + b, 0);
        const percentage = ((value / total) * 100).toFixed(2);
        return `${label} ($${value}, ${percentage}%)`;
      });

      chartDataMensal.labels = chartDataMensal.labels.map((label, index) => {
        const value = chartDataMensal.datasets[0].data[index];
        const total = chartDataMensal.datasets[0].data.reduce(
          (a, b) => a + b,
          0
        );
        const percentage = ((value / total) * 100).toFixed(2);
        return `${label} ($${value}, ${percentage}%)`;
      });

      titheAndOfferingsChartData.labels = titheAndOfferingsChartData.labels.map(
        (label, index) => {
          const value = titheAndOfferingsChartData.datasets[0].data[index];
          const total = titheAndOfferingsChartData.datasets[0].data.reduce(
            (a, b) => a + b,
            0
          );
          const percentage = ((value / total) * 100).toFixed(2);
          return `${label} ($${value}, ${percentage}%)`;
        }
      );

      titheAndOfferingsChartDataMonth.labels =
        titheAndOfferingsChartDataMonth.labels.map((label, index) => {
          const value = titheAndOfferingsChartDataMonth.datasets[0].data[index];
          const total = titheAndOfferingsChartDataMonth.datasets[0].data.reduce(
            (a, b) => a + b,
            0
          );
          const percentage = ((value / total) * 100).toFixed(2);
          return `${label} ($${value}, ${percentage}%)`;
        });

      otherCategoriesChartData.labels = otherCategoriesChartData.labels.map(
        (label, index) => {
          const value = otherCategoriesChartData.datasets[0].data[index];
          const total = otherCategoriesChartData.datasets[0].data.reduce(
            (a, b) => a + b,
            0
          );
          const percentage = ((value / total) * 100).toFixed(2);
          return `${label} ($${value}, ${percentage}%)`;
        }
      );

      otherCategoriesChartDataMonth.labels =
        otherCategoriesChartDataMonth.labels.map((label, index) => {
          const value = otherCategoriesChartDataMonth.datasets[0].data[index];
          const total = otherCategoriesChartDataMonth.datasets[0].data.reduce(
            (a, b) => a + b,
            0
          );
          const percentage = ((value / total) * 100).toFixed(2);
          return `${label} ($${value}, ${percentage}%)`;
        });

      chartDataMission.labels = chartDataMission.labels.map((label, index) => {
        const value = chartDataMission.datasets[0].data[index];
        const total = chartDataMission.datasets[0].data.reduce(
          (a, b) => a + b,
          0
        );
        const percentage = ((value / total) * 100).toFixed(2);
        return `${label} ($${value}, ${percentage}%)`;
      });

      chartDataMissionMonth.labels = chartDataMissionMonth.labels.map(
        (label, index) => {
          const value = chartDataMissionMonth.datasets[0].data[index];
          const total = chartDataMissionMonth.datasets[0].data.reduce(
            (a, b) => a + b,
            0
          );
          const percentage = ((value / total) * 100).toFixed(2);
          return `${label} ($${value}, ${percentage}%)`;
        }
      );

      setData({
        chartData,
        chartDataMensal,
        titheAndOfferingsChartData,
        titheAndOfferingsChartDataMonth,
        otherCategoriesChartData,
        otherCategoriesChartDataMonth,
        chartDataMission,
        chartDataMissionMonth,
      });
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  return (
    <>
      <Navbar />
      <C.Container>
        <C.Titulo>Grafico Geral {valueCurrentYears}</C.Titulo>
        <C.Items>
          {data.chartData ? (
            <Bar
              data={data.chartData}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.label;
                      },
                    },
                  },
                },
              }}
            />
          ) : (
            <p>Carregando gráfico...</p>
          )}
        </C.Items>
        <C.Items>
          {data.titheAndOfferingsChartData ? (
            <Bar
              data={data.titheAndOfferingsChartData}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.label;
                      },
                    },
                  },
                },
              }}
            />
          ) : (
            <p>Carregando gráfico...</p>
          )}
        </C.Items>
        <C.Items>
          {data.otherCategoriesChartData ? (
            <Bar
              data={data.otherCategoriesChartData}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.label;
                      },
                    },
                  },
                },
              }}
            />
          ) : (
            <p>Carregando gráfico...</p>
          )}
        </C.Items>
        <C.Items>
          {data.chartDataMission ? (
            <Bar
              data={data.chartDataMission}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.label;
                      },
                    },
                  },
                },
              }}
            />
          ) : (
            <p>Carregando gráfico...</p>
          )}
        </C.Items>
        <C.Titulo>Grafico Do Mês de {formatCurrentMonth(currentMonthFormat)}</C.Titulo>
        <C.Items>
          {data.chartDataMensal ? (
            <Bar
              data={data.chartDataMensal}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.label;
                      },
                    },
                  },
                },
              }}
            />
          ) : (
            <p>Carregando gráfico...</p>
          )}
        </C.Items>
        <C.Items>
          {data.titheAndOfferingsChartDataMonth ? (
            <Bar
              data={data.titheAndOfferingsChartDataMonth}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.label;
                      },
                    },
                  },
                },
              }}
            />
          ) : (
            <p>Carregando gráfico...</p>
          )}
        </C.Items>
        <C.Items>
          {data.otherCategoriesChartDataMonth ? (
            <Bar
              data={data.otherCategoriesChartDataMonth}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.label;
                      },
                    },
                  },
                },
              }}
            />
          ) : (
            <p>Carregando gráfico...</p>
          )}
        </C.Items>
        <C.Items>
          {data.chartDataMissionMonth ? (
            <Bar
              data={data.chartDataMissionMonth}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.label;
                      },
                    },
                  },
                },
              }}
            />
          ) : (
            <p>Carregando gráfico...</p>
          )}
        </C.Items>
      </C.Container>
    </>
  );
};

export default ChartsPage;
