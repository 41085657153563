import * as C from "./styles";
import { formatDate } from "../../helpers/dateFilter";
// import { categories } from '../../data/categories'

export default function TableItem({ item, dateColor, onEdit, onDelete }) {
  return (
    <C.TableLine>
      <C.TableColumn>{formatDate(item.date)}</C.TableColumn>
      {/* <C.TableColumn>{item.date}</C.TableColumn> */}
      <C.TableColumn>
        <C.Category color={dateColor[item.category].color}>
          {dateColor[item.category].title}
        </C.Category>
      </C.TableColumn>
      <C.TableColumn>{item.title}</C.TableColumn>
      <C.TableColumn>
        <C.Value color={dateColor[item.category].expense ? "red" : "green"}>
          R$ {item.value}
        </C.Value>
      </C.TableColumn>
      <C.TableColumn>
        <C.ActionButtons>
          <C.EditButton onClick={() => onEdit(item)}>Editar</C.EditButton>
          <C.DeleteButton onClick={() => onDelete(item)}>Excluir</C.DeleteButton>
        </C.ActionButtons>
      </C.TableColumn>
    </C.TableLine>
  );
}
